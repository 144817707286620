/* eslint no-param-reassign: 0 */
import moment from 'moment';
import Cookies from 'js-cookie';
import { frontendURL } from '../../helper/URLHelper';

Cookies.defaults = { sameSite: 'Lax' };

export const getLoadingStatus = state => state.fetchAPIloadingStatus;
export const setLoadingStatus = (state, status) => {
  state.fetchAPIloadingStatus = status;
};

export const setUser = (userData, expiryDate) => {
  if (typeof ionic === 'undefined') {
    Cookies.set('user', userData, {
      expires: expiryDate.diff(moment(), 'days'),
    });
  }
  else {
    localStorage.setItem('user', JSON.stringify(userData));
  }
};

export const getHeaderExpiry = response => moment.unix(response.headers.expiry);

export const setAuthCredentials = response => {
  const expiryDate = getHeaderExpiry(response);
  if (typeof ionic === 'undefined') {
    Cookies.set('auth_data', response.headers, {
      expires: expiryDate.diff(moment(), 'days'),
    });
  }
  else {
    localStorage.setItem('auth_data', JSON.stringify(response.headers));
  }
  setUser(response.data.data, expiryDate);
};

export const clearCookiesOnLogout = () => {
  if (typeof ionic === 'undefined') {
    Cookies.remove('auth_data');
    Cookies.remove('user');
  }
  else {
    localStorage.removeItem('auth_data');
    localStorage.removeItem('user');
  }
  window.location = frontendURL('login');
};

import { render, staticRenderFns } from "./LabelBox.vue?vue&type=template&id=4bbcc4c5&scoped=true&"
import script from "./LabelBox.vue?vue&type=script&lang=js&"
export * from "./LabelBox.vue?vue&type=script&lang=js&"
import style0 from "./LabelBox.vue?vue&type=style&index=0&id=4bbcc4c5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bbcc4c5",
  null
  
)

export default component.exports